/*----Global----*/
* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

html {
  font-family: "Avenir Next", "Montserrat", Arial, sans-serif;
  font-weight: 100;
  position: relative;
  min-height: 100%;
}

body {
  width: 80%;
  margin: auto;
  /*  background-image: url('./assets/backgroundAccents/bgAccent1.svg');
  background-size: contain;
  background-repeat: no-repeat;*/
}

h1 {
  font-size: 40px;
  font-weight: bold;
  color: #000000;
}

h2 {
  font-size: 30px;
  font-weight: 400;
  color: #000000;
}

h5 {
  font-size: 20px;
  font-weight: bold;
  color: #000000;
}

p {
  font-size: 20px;
  font-weight: 200;
}

h2 span {
  font-weight: 500;
}

h2 span:nth-child(1) {
  color: #e65656;
}

h2 span:nth-child(2) {
  color: #2fc4c2;
}

h2 span:nth-child(3) {
  color: #a1f575;
}

h2 span:nth-child(4) {
  color: #7880b5;
}

.centerHeader {
  text-align: center;
  margin-top: 40px;
}

.centerText {
  text-align: center;
}

.scroll {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 700px;
}

/*----Nav----*/

.nav {
  display: inline-block;
  width: 100%;
  line-height: 100px;
}

.nav ul {
  float: right;
  list-style: none;
}

.nav ul li {
  display: inline-block;
}

.logo {
  font-weight: bold;
  font-size: 40px;
  text-decoration: none;
}

.link {
  text-decoration: none;
  font-size: 20px;
  font-weight: bold;
  color: #000000;
  margin-left: 32px;
}

.mobileNavLinks {
  display: none;
}

label {
  margin-right: 7.5em;
  font-size: 20px;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
}

#toggleResponsiveNav {
  display: none;
}

.mobileNavLinks ul {
  text-align: center;
  width: 100%;
  margin: 0px;
  display: none;
  background-color: rgba(255, 255, 255, 0.1);
}

.mobileNavLinks ul li {
  width: 100%;
  margin: 0;
  border-bottom: 1px solid black;
}

#toggleResponsiveNav:checked ~ ul {
  display: block;
}

/*----Home----*/
.landing {
  height: 90vh;
  position: relative;
}

.landingWrapper {
  display: flex;
  justify-content: space-between;
}

.landingLeft {
  margin-top: 134px;
}

.landingRight {
  margin-top: 70px;
}

/*----Resume----*/
.aboutSection {
  text-align: center;
  margin-top: 50px;
}

.aboutSection .underline {
  border-bottom: 3px solid #2fc4c2;
  width: 132px;
  margin: auto;
}

.workSection .underline {
  border-bottom: 3px solid #e65656;
  width: 300px;
  margin: auto;
}

.eduSection .underline {
  border-bottom: 3px solid #a1f575;
  width: 200px;
  margin: auto;
}

.workSection {
  text-align: center;
  margin-top: 300px;
}

.eduSection {
  text-align: center;
  margin-top: 125px;
  margin-bottom: 50px;
}

.work1,
.work2,
.edu1,
.edu2 {
  margin-top: 34px;
}

.title {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.title p {
  color: #a8a4a4;
}

.workLeft {
  text-align: left;
}

.workRight {
  text-align: right;
}

.work1 .workDesc,
.work2 .workDesc,
.edu1 .eduDesc {
  text-align: left;
}

.edu2 .eduDesc {
  text-align: right;
}

/*----Work----*/
.devIcons {
  display: flex;
  justify-content: space-around;
  margin-top: 40px;
}

.devIcon {
  height: 54px;
  width: 54px;
}

.devIcon:nth-child(3) {
  fill: #56eeec;
}

.portfolioItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 360px;
}

.projectDesc {
  margin: 20px;
  text-align: center;
  width: 420px;
}
/* 
.projectDesc .second {
  text-align: left;
  width: 420px;
} */

.projectDesc span,
.projectDesc span a {
  color: #56eeec;
  text-decoration: none;
}

.grub {
  max-height: 750px;
}

.endText p {
  text-align: center;
}

/*----Blog----*/
.blogContainer {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  /* background-color: red; */
}

.blogPostRow {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  /* margin-top: 30px; */
}

.blogSeriesCard {
  /* margin-right: 40px; */
  margin-top: 30px;
  text-align: left;
  width: 340px;
  height: 430px;
  border-radius: 20px;
  box-shadow: 0px 8px 16px #acacac;
  overflow: hidden;
}

.blogSeriesCard h1 {
  margin-left: 30px;
  margin-top: 60px;
  width: 248px;
  height: 110px;
}

.blogCardAccent {
  width: 316px;
  height: 316px;
  border-radius: 100%;
  margin-top: 75px;
  margin-left: 100px;
}

.textContainer {
  width: 860px;
  text-align: center;
  height: auto;
  margin: auto;
}

/*----Footer----*/
.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-bottom: 40px; */
  /* height: 90px; */
  margin-top: 90px;
}

.externalLinks img {
  flex: 1;
  height: 30px;
  width: 30px;
}

.externalLinks img:last-child {
  margin-left: 40px;
}

.contact a {
  text-decoration: none;
}

/*----Responsive----*/

@media only screen and (max-width: 1170px) {
  .landingRight img {
    max-height: 350px;
    width: auto;
    margin-top: 80px;
  }

  .textContainer {
    width: 100%;
  }
}

/*@media only screen and (max-width: 1100px) {
  body {
    background-color: red;
  }
}*/

@media only screen and (max-width: 878px) {
  /*body {
    background-color: red;
  }*/

  .nav ul {
    display: none;
  }

  .mobileNavLinks {
    display: block;
  }

  .landingWrapper {
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  .landingLeft {
    margin-top: 40px;
  }

  .landingRight img {
    margin-top: -30px;
  }

  .scroll {
    display: none;
  }

  /*here*/

  .portfolioDisplay {
    margin-top: -200px;
  }

  .portfolioItem {
    flex-direction: column;
  }

  .portfolioItem .projectDesc {
    text-align: center;
  }
}

@media only screen and (max-width: 678px) {
  body {
    /*background-color: blue;*/
  }

  .logo {
    margin-top: 20px;
    height: 75px;
    width: auto;
    font-size: 30px;
  }

  .nav ul {
    display: none;
  }

  label {
    margin-top: 7px;
    right: -95px;
  }

  .landingWrapper {
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  .landingLeft {
    margin-top: 40px;
  }

  .landingRight img {
    margin-top: -50px;
    max-height: 250px;
    width: auto;
  }

  .portfolioItem .projectDesc {
    width: 300px;
  }

  .footer {
    margin-top: 130px;
  }

  .footer .middle {
    display: none;
  }

  .externalLinks img:last-child {
    margin-left: 20px;
  }
}
